

#head-animation,
#eyebrows_color-right,
#eyebrows_color-left,
#lips,
#eyes-animation {
  transition: all 0.25s cubic-bezier(0.25, 0.1, 0.25, 1);
}

#eyes-animation {
  transform: translate(
    calc(-3px + 6px * var(--mouse-x)),
    calc(-1px + 2px * var(--mouse-y))
  );
  transition: all 0.05s ease-out 0.01s;
}

#head-animation {
  transform-origin: center center;
  /* transition: all 0.2s ease-out; */
}

#eyebrows_color-right {
  transform-origin: center center;
  transform: translateY(calc(-3px + 4px * var(--mouse-y)));
  /* transition: all 0.1s ease-out 0.01s; */
}

#eyebrows_color-left {
  transform-origin: center center;
  transform: translateY(
    calc(-4px + 45px * var(--mouse-y) * (0.1 * var(--mouse-x)))
  );
  /* transition: all 0.1s ease-out 0.01s; */
}

#lips {
  transform-origin: center center;
  /* transform: translate(calc(-5px + 2px * var(--mouse-y))); */
  /* transform: skew(calc(-5deg + 15deg * var(--mouse-y))); */
  transform: translateY(calc(-1px + 2px * var(--mouse-y)));
  /* transition: all 0.1s ease-out 0.01s; */
  fill: rgb(238, 162, 167);
}

#helper-collar-outline {
  /* background-color: var(--tShirtCollarColor); */
  filter: brightness(90%); /* Makes the color 10% darker */
}

@media screen and (max-width: 768px) {


 

  /* #head-animation {
      transition: all 0.6s cubic-bezier(0.25, 0.1, 0.25, 1); /* Adjust the cubic-bezier values as needed
    } */

  #eyebrows_color-right,
  #eyebrows_color-left,
  #lips,
  #eyes-animation {
    transition: all 0.25s cubic-bezier(0.25, 0.1, 0.25, 1); /* Adjust the cubic-bezier values as needed */
  }

  #eyes-animation {
    transition: all 0.25 cubic-bezier(0.25, 0.1, 0.25, 1); /* Adjust the cubic-bezier values as needed */
  }
  #head-animation,
  #eyebrows_color-right,
  #eyebrows_color-left,
  #lips,
  #eyes-animation {
    transition: all 0.65s cubic-bezier(0.25, 0.1, 0.25, 1);
  }
}
